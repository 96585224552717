<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title-large> {{ headerTitle }} </f7-nav-title-large>
    </f7-navbar>
    <!-- Page content-->

    <div class="block-title">รายการโอนเงินของท่านล่าสุด</div>

    <div
      class="card card-outline"
      v-for="(item, index) in transferList"
      :key="index"
    >
      <div class="card-header">Username : {{ item.customerRefUser }}</div>
      <div class="card-content card-content-padding">
        <p>จำนวนเงิน : {{ item.transferAmount.toLocaleString() }} บาท</p>
        <p>
          เวลาที่โอนเงิน :
          {{ moment(item.transferDateTime).locale("th").format("Do MMMM YYYY HH:mm น.") }}
        </p>
        
        <div class="item-after">
          สถานะ :
          <span class="badge color-blue" v-if="item.status === 'new'">รายการใหม่</span>
          <span class="badge color-green" v-if="item.status === 'completed'">ผ่านการตรวจสอบ</span>
          <span class="badge color-red" v-if="item.status === 'rejected'">ตรวจสอบไม่ผ่าน</span>
        </div>
      </div>
      <div class="card-footer">
        <button
          class="col button button-fill"
          @click="
            () => {
              f7router.navigate(`/detail/${item.id}`);
            }
          "
        >
          ดูรายละเอียด
        </button>
      </div>
    </div>
  </f7-page>
</template>
<script>
import { ref, onMounted } from "vue";
import { f7, f7ready } from "framework7-vue";
import store from "../js/store";
import ApiService from "../utils/api.service";
import settings from "../config/settings";
import moment from "moment";

export default {
  data() {
    return {
      companyName: "",
      headerTitle: "ประวัติการแจ้งโอนเงิน",
      headerDescription: "",
      formTitle: "",
      imageData: null,
      isSubmit: false,
      transferList: [],
      moment,
      lineId: null,
      lineData: null,
    };
  },
  props: {
    f7router: Object,
  },
  setup() {
    onMounted(() => {
      f7ready(() => {
        f7.dialog.preloader();
      });
    });
  },

  mounted() {
   

     

    if (settings.useLineLiff) {
      window.liff.ready.then(() => {
        // do something you want when liff.init finishes
        window.liff.getProfile().then((res) => {
          this.lineId = res && res.userId ? res.userId : null;
          this.lineData = res;
          this.lineData.email = liff.getDecodedIDToken().email;
          store.dispatch("setLine", res);
          this.fetchData();
        });
      });
    }
  },
  methods: {
    currentDateTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      return now.toISOString().slice(0, 16);
    },
    fetchData() {
      ApiService.query("transfers", `_limit=10&_sort=created_at:DESC${this.lineId && this.lineId !== "" ? `&lineId=${this.lineId}` : ''}`)
        .then((res) => {
          console.log('all res', res);
          f7.dialog.close();
          if (res && res.status === 200) {
            this.transferList = res.data;
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
};
</script>
<style scoped>
.dateTimeInput {
  max-width: 150vw !important;
}
</style>
